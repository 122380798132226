#header {
  position: fixed;
  width: 250px;
  z-index: 99999;
}
#header .pro-sidebar {
  height: 100vh;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0;
  background: #6caeff;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #084187;
  font-weight: bold;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background: #2C487B;
  color: #f5f7ff;
}
#header .logo {
  padding: 20px;
}
#header .pro-sidebar .pro-menu a {
  color: #084187;
  display: block;
  padding-left: 8px;
}
#header .pro-sidebar .pro-menu a:hover:not(.active) {
  color: whitesmoke; 
}
#header .pro-sidebar .pro-menu {
  color: #084187;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background: transparent !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
  border-bottom: 1px solid whitesmoke;
}

.pro-sidebar .pro-menu .pro-menu-item .active {
  background-color: #2C487B;
  padding-left: 8px;
  padding-block: 8px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  white-space: pre-wrap;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content:hover:not(.active) {
  color: whitesmoke; 
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  padding-inline: 25px;
}
.pro-sidebar
  > .pro-sidebar-inner
  > .pro-sidebar-layout
  .pro-sidebar-header
  label {
  text-align: left;
  font: normal normal 900 20px/26px Avenir;
  letter-spacing: -0.2px;
  color: #3d3b4f;
  opacity: 1;
}
.pro-sidebar
  > .pro-sidebar-inner
  > .pro-sidebar-layout
  .pro-sidebar-header
  .dropdown
  p {
  text-align: left;
  font: normal normal medium 16px/22px Avenir;
  letter-spacing: 0px;
  color: #084187;
  font-size: 14px;
  opacity: 1;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0px;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 0px;
}
.pro-sidebar
  > .pro-sidebar-inner
  > .pro-sidebar-layout
  .pro-sidebar-footer
  label {
  padding: 10px 30px;
  text-align: left;
  font: normal normal medium 14px/19px Avenir;
  letter-spacing: 0px;
  color: #6caeff;
  opacity: 1;
  font-size: 14px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  border-color: #084187;
}
@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
